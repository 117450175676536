<template>
  <div class="section-intro">
    <div class="section-intro__wrapper">
      <div class="section-intro__inner">
        <div class="section-intro__what">
          <div class="section-intro__row">
            <div class="section-intro__col">
              <div class="section-intro__title">
                <img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/detail/${page}/txt-whatis-${$i18n.locale}.png`))" :alt="`what is ${page}`">
              </div>
            </div>
            <div class="section-intro__col">
              <div class="section-intro__content">
                <div class="section-intro__txt">{{ $t(`${this.page}.intro.what_is`) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-intro__character">
          <div class="section-intro__row character-row">
            <div class="section-intro__col pc-appear" >
              <div class="section-intro__title character-avt">
                <img class="lazyload" :data-src="webp(require(`../../../public/img/detail/${page}/avt-tomi.png`))" :alt="`${page}`">
              </div>
            </div>
            <div class="section-intro__col">
              <div class="section-intro__content">
                <img class="character-img pc-appear" :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/detail/${page}/txt-brand-${$i18n.locale}.png`))" alt="">
                <div class="character-content sp-appear">
                  <div><img class="lazyload" :data-src="webp(require(`../../../public/img/detail/${page}/sp/avt-tomi.png`))" :alt="`${page}`"></div>
                  <div><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/detail/${page}/sp/txt-brand-${$i18n.locale}.png`))" :alt="`${page}`"></div>
                </div>
                <div class="section-intro__txt">{{ $t(`${this.page}.intro.brand_character`) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-intro__gallery">
          <div class="gallery-row">
            <div class="gallery-col">
              <div class="section-intro__title">
                <img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/detail/${page}/txt-gallery-${$i18n.locale}.png`))" alt="gallery">
                <div style="font-size: 16px; color: #7d7a7a; margin-top: 1.5rem" v-show="page==='koimucho'">{{ $t(`${this.page}.intro.note`) }}</div>
              </div>
            </div>
            <div class="gallery-col">
              <div class="gallery-slide">
                <div class="gallery-slide__inner">
                  <swiper :options="swiperOption" ref="mySwiper">
                    <!-- slides -->
                    <swiper-slide v-for="(url, index) in galleryURL" :key="index" >
                      <div class="swiper-slide__content">
                        <img @click="showGallery(url.id)"
                             class="lazyload" :data-src="webp(require(`../../../public/img/detail/${page}/gallery/${url.name}`))"
                             :src="index == 0 ? webp(require(`../../../public/img/detail/${page}/gallery/${url.name}`)) : ``"
                             :class="{question: page==='koimucho'}"
                             :alt="`${page} story`">
                        <img @click="showGallery(url.id)"
                             class="lazyload" :data-src="webp(require(`../../../public/img/detail/${page}/gallery/${url.quiz}`))"
                             :src="index == 0 ? webp(require(`../../../public/img/detail/${page}/gallery/${url.quiz}`)) : ``"
                             :class="{answer: page==='koimucho'}"
                             v-if="page==='koimucho'"
                             :alt="`${page} story`">
                      </div>
                    </swiper-slide>
                    <!-- <swiper-slide><img src="../../../public/img/detail/karamucho/slide/slide-2.png" alt=""></swiper-slide>
                    <swiper-slide><img src="../../../public/img/detail/karamucho/slide/slide-3.png" alt=""></swiper-slide>
                    <swiper-slide><img src="../../../public/img/detail/karamucho/slide/slide-1.png" alt=""></swiper-slide> -->
                  </swiper>
                  <div class="gallery-slide__navigation">
                    <div class="gallery-slide__control gallery-slide__control--prev" slot="button-prev"></div>
                    <div class="gallery-slide__control gallery-slide__control--next" slot="button-next"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
//import Lightbox from 'vue-my-photos'

export default {
  name: 'Intro',
  props: {
    page: String
  },
  data() {
    return {
      karamucho: {
        what_is: "In 1984, Koike-ya introduced its first KARAMUCHO potato chips to the Japanese market. Back then, people were so excited of the amazing mixture of spicy, sweet and sour flavor. It's even said to have kick started the spicy food trend in Japan. It had opened to the success of a number or KARAMUCHO brands, and made KARAMUCHO the best-selling spicy chips in Japan. Coming to Vietnam, we believe that KARAMUCHO would create such miracle.",
        character: "Having a special blend of hot, spicy, sweet and sour with a one of the kind spicy flavor, KARAMUCHO is expected to become a phenomenon among the Vietnamese consumers, especially the young. The secret to the KARAMUCHO’s seductive flavor is the fresh sliced potatoes, kept under calculated conditions to prevent them from sprouting. KARAMUCHO has an exceptional spicy flavor that can’t be mistaken from any other snacks, fused with sweet and sour that keep you munching."
      },
      koimucho: {
        what_is: "In 1984, Koike-yre of spicy, sweet and sour flavor. It's even said to have kick started the spicy food trend in Japan. It had opened to the success of a number or KARAMUCHO brands, and made KARAMUCHO the best-selling spicy chips in Japan. Coming to Vietnam, we believe that KARAMUCHO would create such miracle.",
        character: "Having a special blend of hot, spicy, sweet and sour with a one of the kind spicy flavor, KARAMUCHO is expected to become a phenomenon among the Vietnamese consumers, especially the young. The secret to the KARAMUCHO’s seductive flavor is the fresh sliced potatoes, kept under calculated conditions to prevent them from sprouting. KARAMUCHO has an exceptional spicy flavor that can’t be mistaken from any other snacks, fused with sweet and sour that keep you munching."
      },
      //galleryDir: '../../../public/img/detail/karamucho/gallery/',
      fileType: '',
      galleryItem: {},
      galleryURL: [],
      currentSlide: 0,
      firstSlide: true,
      lastSlide: false,
      totalSlide: 12,
      swiperOption: {
        slidesPerView: 'auto',
        speed: 700,
        navigation: {
          nextEl: '.gallery-slide__control--next',
          prevEl: '.gallery-slide__control--prev',
          disabledClass: 'disable'
        },
      },
    }
  },
  components : {
    swiper,
    swiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  created() {
    this.galleryTotal()
  },
  watch: {
    currentSlide: function() {
      
      // else {
      //   this.lastSlide = false
      //   this.firstSlide = false  
      // }
    } 
  },
  methods: {
    showGallery: function(id) {
      this.$emit('showGallery', this.totalSlide, id, 'intro')
    },
    galleryTotal() {
      if (this.page==='karamucho') {
        this.totalSlide = 12
      } else {
        this.totalSlide = 30
      }
      for(let i = 1; i <= this.totalSlide; i++) {
        let obj = {}
        obj.name = (i) + ".png";
        obj.quiz = (i) +"_answer.png";
        obj.id = i;
        this.galleryURL.push(obj);
      }
    },
  },
}

</script>

<style lang="scss" scoped>
  @import '../../assets/scss/mixin/_mixin';
  .koimucho {
    .section-intro {
      &__character {
        &:before {
          background: url(../../../public/img/detail/koimucho/bg-snack-intro.png) no-repeat center / contain;
          left: -13%;
          bottom: -28%;
          width: 23%;
          padding-top: 15%;
        }
      }
      &__character {
        .character-avt {
          padding-top: 0;
        }
      }
    }
    .swiper-slide {
      width: 40%;

      @include media(set-max-w, 800) {
        width: 50%;
      }
      @include media(set-max-w, 500) {
        width: 100%;
      }
    }
  }
  .karamucho {
    .section-intro {
      &__character {
        &:before {
          background: url(../../../public/img/detail/karamucho/bg-snack-intro.png) no-repeat center / contain;
        }
      }
    }
    .swiper-slide {
      width: 33.33%;

      @include media(set-max-w, 800) {
        width: 50%;
      }
      @include media(set-max-w, 500) {
        width: 100%;
      }
    }
  }
  .swiper-slide {
    cursor: pointer;
    overflow: hidden;
    width: 40%;
    padding-right: 2rem;

    @include media(phone) {
      padding-right: 0;
    }
    &:hover {
      .question {
        opacity: 0;
      }
    }
    &__content {
      position: relative;
    }
    .question {
      opacity: 1;
      transition: opacity .4s ease-in-out;
    }
    .answer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }
  .section-intro {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    transform: translateY(-5rem);
    z-index: 1;

    @include media(tablet) {
      transform: translateY(0)
    }
    @include media(phone) {
      transform: translateY(0)
    }
    &__wrapper {
      padding-left: 10.5%;

      @include media(tablet) {
        padding-left: 4rem;
        padding-top: 4rem;
      }
      @include media(phone) {
        padding-left: 2rem;
        padding-top: 2rem;
      }
    }
    &__inner {
      position: relative;
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2.8rem;

      @include media(tablet) {
        margin: 0 -1.4rem
      }
    }
    &__col {
      padding: 0 2.8rem;

      @include media(tablet) {
        padding: 0 1.4rem
      }
      &:first-child {
        width: 33.33%;
        
        @include media(tablet) {
          width: 30%;
        }
        @include media(phone) {
          margin-bottom: 2rem;
          width: 26.5rem ;
        }
      }
      &:last-child {
        padding-right: 1rem;
        width: calc(100% - 33.33%);

        @include media(tablet) {
          width: 70%;
        }
        @include media(phone) {
          width: 100% ;
        }
      }
    }
    &__content {
      padding-right: 4rem;

      @include media(set-max-w, 800) {
        padding-right: 3rem;
      }
    }
    &__txt {
      width: 70rem;
      max-width: 100%;
      font-size: 1.6rem;
      line-height: 3rem
    }
    &__what {
      background: #fff;
      padding-left: 10rem;
      padding-top: 18rem;
      padding-bottom: 19rem;

      @include media (set-max-w, 1366) {
        padding-left: 5rem;
        padding-top: 9rem;
        padding-bottom: 9rem;
      }
      @include media(tablet) {
        padding-top: 6rem;
        padding-bottom: 7rem;
        padding-left: 5rem;
      }
      @include media(phone) {
        padding-left: 2.5rem;
        padding-top: 4rem;   
        padding-bottom: 8.5rem;     
      }
      .section-intro__title {
        @include media(tablet) {
          margin-bottom: 2rem;
        }
      }
    }
    &__character {
      position: relative;
      padding-left: 10rem;
      padding-bottom: 30rem;
      background: rgba(255,255,255, .3);
      
      @include media (set-max-w, 1366) {
        padding-bottom: 20rem;
        padding-left: 5rem;
      }
      @include media(tablet) {
        padding-left: 4rem;
        padding-bottom: 20rem;
      }
      @include media(phone) {
        padding-left: 2.4rem;
        padding-bottom: 35rem;
      }
      &:before {
        content: '';
        position: absolute;
        left: -12rem;
        bottom: -8rem;
        width: 32rem;
        height: 20rem;


        @include media(phone) {
          display: none
        }
      }
      .character {
        &-content {
          @include media(phone) {
            display: flex;
            margin-bottom: 3.5rem;
            padding-right: 1rem;
            div {
              width: 50%;

              &:first-child {
                @include media(phone) {
                  width: 30%;
                  margin-left: 0;
                  margin-right: 2rem;
                }
              }
              &:last-child {
                @include media(phone) {
                  width: calc(70% - 2rem)
                }
              }
            }
          }
        }
        &-avt {
          padding-top: 5rem;

          @include media(tablet) {
            padding-top: 2rem;
          }
        }
        &-row {
          transform: translateY(-7rem);

          @include media(tablet) {
            transform: translateY(-4rem)
          }
          @include media(phone) {
            transform: translateY(-6rem)
          }
        }
        &-img {
          margin-bottom: 10rem;

          @include media(tablet) {
            margin-bottom: 6rem;
          }
        }
      }
    }
    &__gallery {
      position: relative;
      padding-left: 10rem;
      margin-top: -25rem;
      margin-bottom: 15rem;
      //background: rgba(255,255,255, .3);

      @include media (set-max-w, 1366) {
        margin-top: -20rem;
        margin-bottom: 10rem;
        padding-left: 5rem;
      }
      @include media(tablet) {
        margin-top: -15rem;
        margin-bottom: 8rem;
        padding-left: 4rem;
      }
      @include media(phone) {
        margin-top: -37rem;
        margin-bottom: 0;
        padding-left: 2rem;
        padding-top: 0;
      }
      .gallery {
        overflow-x: hidden;
        overflow-y: auto;
        &-row {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -7.3rem;

          @include media (set-max-w, 1366) {
            margin: 0 -2.8rem
          }
          @include media(tablet) {
            margin: 0 -1.4rem
          }
        }
        &-col {
          padding: 0 7.3rem;

          @include media (set-max-w, 1366) {
            padding: 0 2.8rem
          }
          @include media(tablet) {
            padding: 0 1.4rem
          }          
          &:first-child {
            width: 25%;

            @include media(tablet) {
              width: 30%;
            }     
            @include media(phone) {
              width: 100%;
              margin-bottom: 3.5rem;
            }     
          }
          &:last-child {
            width: 75%;

            @include media(tablet) {
              width: 70%;
            }
            @include media(phone) {
              width: 100%;
            } 
          }
        }
        &-slide {
          position: relative;


          &__inner {

            @include media(phone) {
              padding-right: 1.5rem;
            }
          }
          &__navigation {
            display: flex;
            margin-top: 4rem;

            @include media(phone) {
              justify-content: flex-end
            }
          }
          &__control {
            width: 5.5rem;
            height: 5.5rem;
            border-radius: 50%;
            background: url(../../../public/img/icon/icon-arrow-half.png) no-repeat center #fff;
            cursor: pointer;

            &:focus {
              outline: none
            }
            &--prev {
              margin-right: 1.5rem;
              transform: rotate(3.12rad)
            }
          }
        }
      }
    }
  }

  .webp {
    .koimucho {
      .section-intro {
        &__character {
          &:before {
            background: url(../../../public/img/detail/koimucho/bg-snack-intro.webp) no-repeat center / contain;
          }
        }
      }
    }
    .karamucho {
      .section-intro {
        &__character {
          &:before {
            background: url(../../../public/img/detail/karamucho/bg-snack-intro.webp) no-repeat center / contain;
          }
        }
      }
    }
    .section-intro {
      &__gallery {
        .gallery {
          &-slide {
            &__control {
              background: url(../../../public/img/icon/icon-arrow-half.webp) no-repeat center #fff;
            }
          }
        }
      }
    }
  }
</style>